type EnvironmentVariable = {
  NEXT_PUBLIC_APP_ENV: string
  NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN: string
  NEXT_PUBLIC_POSTHOG_KEY: string
}

const env: {
  local: EnvironmentVariable
  staging: EnvironmentVariable
  production: EnvironmentVariable
} = {
  local: {
    NEXT_PUBLIC_APP_ENV: 'local',
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN:
      'default:development.68b6de0d465eb49d4d235583d1882552ff5e8f55ac957d560eea1899',
    NEXT_PUBLIC_POSTHOG_KEY: 'phc_GDdZ8JzPeEP8VxSAcLOkuSHqAyUIn2x47anFcaKfISF',
  },
  staging: {
    NEXT_PUBLIC_APP_ENV: 'staging',
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN:
      'default:development.68b6de0d465eb49d4d235583d1882552ff5e8f55ac957d560eea1899',
    NEXT_PUBLIC_POSTHOG_KEY: 'phc_GDdZ8JzPeEP8VxSAcLOkuSHqAyUIn2x47anFcaKfISF',
  },
  production: {
    NEXT_PUBLIC_APP_ENV: 'production',
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN:
      'default:production.c02b86c17bb911f84d222a913d70ecd81cfd30c66625b7f7dacbcfe5',
    NEXT_PUBLIC_POSTHOG_KEY: 'phc_MurKUKOVDjAyqOvF5GYt2ojo5jtXUtFCs2My4E0tIu0',
  },
}

export function getClientEnvVariable(key: keyof EnvironmentVariable) {
  if (typeof window !== 'undefined') {
    const environment =
      window.location.hostname === 'app.gratiahealth.com'
        ? 'production'
        : window.location.hostname === 'localhost'
          ? 'local'
          : 'staging'
    return env[environment][key]
  } else {
    // fallback is we're calling this on the server so this is globally accessible
    const environment =
      process.env.APP_ENV === 'production' ? 'production' : process.env.APP_ENV === 'local' ? 'local' : 'staging'
    return env[environment][key]
  }
}
